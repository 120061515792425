import {httpGet, httpPost, httpPut} from '@/plugins/request'

/**
 * 项目列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjects(query = [])
{
    return httpGet('/manage/project/project', query)
}

/**
 * 创建项目
 * @param data
 * @returns {Promise<*>}
 */
export function apiCreateProject(data = [])
{
    return httpPost('/manage/project/project', data)
}

/**
 * 修改项目
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiPutProject(id, query = [])
{
    return httpPut(`/manage/project/project/${id}`, query)
}


/**
 * 资金池
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectBalance(query = [])
{
    return httpGet(`/manage/project/balance`, query)
}

/**
 * 计算资金池余额
 *
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetCalcProjectBalance(id, query = [])
{
    return httpGet(`/manage/project/balance/${id}/calc`, query)
}


/**
 * 添加资金池
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiCreateProjectBalance(query = [])
{
    return httpPost(`/manage/project/balance`, query)
}

/**
 * 修改资金池
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiUpdateProjectBalance(id, query = [])
{
    return httpPut(`/manage/project/balance/${id}`, query)
}
